@use 'src/styles/breakpoints' as *;
@use 'src/styles/mixins' as *;
@use 'src/styles/theme' as *;

// easeOutSine
$easing: cubic-bezier(0.39, 0.575, 0.565, 1);

.suspensionScreen {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  color: #fff;
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    var(--umai-suspension-background-mobile);
  transition: all 0.6s $easing;

  @media (min-width: 600px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      var(--umai-suspension-background);
  }

  .content {
    margin: 80px 20px 60px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 800px;

    @include desktopAndAbove {
      margin: 100px 40px 80px 40px;
    }
  }

  .language {
    position: absolute;
    top: 20px;
    right: 20px;

    @include tabletAndAbove {
      top: 30px;
      right: 30px;
    }

    @include desktopAndAbove {
      top: 40px;
      right: 40px;
    }
  }

  .languageButton {
    font-size: pxToRem(16);
    color: #fff;
    border: 0;

    svg {
      margin-left: 5px;
    }

    @include desktopAndAbove {
      font-size: pxToRem(20);
    }

    &:hover {
      color: $colorPrimary;

      path {
        fill: $colorPrimary;
      }
    }

    &:focus-visible {
      outline: 1px solid $colorPrimary;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    display: block;
    width: 206px;
    padding-bottom: 12px;
    border-bottom: 1px solid #fff;
    transition: all 0.4s $easing 0.4s;

    @include tabletAndAbove {
      width: 280px;
      border-bottom: 2px solid #fff;
    }

    @include desktopAndAbove {
      width: 350px;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 1px solid $colorPrimary;
    }

    svg,
    path {
      transition: all 0.2s $easing;
    }

    &:hover {
      svg {
        filter: drop-shadow(0 0 4px rgba($colorPrimary, 0.4));

        path {
          fill: $colorPrimary;
        }
      }
    }
  }

  .tagline {
    margin: 10px 0 0;
    font-size: pxToRem(18);
    line-height: 1;
    font-weight: 400;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s $easing 0.7s;

    @include tabletAndAbove {
      font-size: pxToRem(24);
    }

    @include desktopAndAbove {
      font-size: pxToRem(32);
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
    }
  }

  .title {
    margin: auto 0;
    font-size: pxToRem(24);
    line-height: 1.2;
    font-weight: 500;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    transition: opacity 0.7s $easing 1.2s;

    @include tabletAndAbove {
      font-size: pxToRem(28);
    }

    @include desktopAndAbove {
      font-size: pxToRem(30);
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
    }
  }

  .visit {
    margin: 0 0 30px;
    font-size: pxToRem(18);
    line-height: 1.2;
    font-weight: 400;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    transition: opacity 0.4s $easing 1.1s;

    @include tabletAndAbove {
      font-size: pxToRem(24);
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    }
  }

  .footer {
    transition: all 0.5s $easing 0.8s;
  }

  .button {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 374px;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #fff;
    background: transparent;
    font-weight: 500;
    font-size: pxToRem(18);
    color: #fff;
    line-height: 22px;
    transition: all 0.2s ease-out;

    @include tabletAndAbove {
      border: 2px solid #fff;
    }

    &:hover {
      text-decoration: none;
      border-color: $colorPrimary;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 8px rgba($colorPrimary, 0.4);
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:focus-visible {
      border-color: $colorPrimary;
    }
  }

  // Initial states before showing the screen
  // `transition` property for every element is used to make them visible
  &.isLoading {
    opacity: 0;

    .logo {
      transform: translateY(-40px);
      opacity: 0;
    }

    .tagline {
      opacity: 0;
    }

    .title {
      opacity: 0;
    }

    .visit {
      opacity: 0;
    }

    .footer {
      transform: translateY(30px);
      opacity: 0;
    }
  }
}
