@use 'src/styles/breakpoints' as *;
@use 'src/styles/theme' as *;
@use 'src/styles/mixins' as *;

.accessMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $colorBG;
  box-shadow: 0px 2px 4px 2px #0000002f;
  padding: 1.5rem;
  border-radius: $border-radius-xl;
  min-height: 450px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 5;
  gap: 1rem;
  width: 600px;
  top: 25%;
  left: 0;
  right: 0;
  @include mobile() {
    gap: 0.75rem;
    width: 95%;
    top: 15%;
    left: 2.5%;
  }

  .header {
    font-weight: 300;
    color: var(--color-primary);
    margin: 0;
  }

  .title {
    font-weight: 400;
    font-size: $font-size-xxl;
    margin: 0;
  }

  .description {
    font-size: $font-size-lg;
    margin: 0;
    padding: 0 1rem;
    ul {
      margin: 0;
    }
    @include mobile() {
      padding: 0;
    }
  }

  .subTitle {
    font-size: $font-size-lg;
    font-weight: 500;
    color: var(--color-primary);
    margin: 0;
  }

  .footer {
    font-size: $font-size-md;
    width: 100%;
    margin: 0;

    button {
      @include button-reset;
      background-color: $colorPrimary;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
      color: var(--text-white);
      padding: 10px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: $border-radius-xl;
      width: 340px;
      height: 60px;
      @include mobile {
        width: 100%;
      }

      &:focus {
        outline: 1px solid $focus-outline;
      }

      &:hover {
        background-color: darken($color: $colorPrimary, $amount: 5);
      }

      &:disabled {
        background-color: var(--color-grey);
        cursor: default;
        outline: none;
      }
    }
  }
}
