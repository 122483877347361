@use 'src/styles/breakpoints' as *;
@use 'src/styles/theme' as *;
@use 'src/styles/mixins' as *;

.referralBanner {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.modal {
  max-width: 696px;
  max-height: 495px;
  padding: 1rem;

  @include tabletAndAbove() {
    padding: 1rem 6rem;
  }
}

.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .title {
    font-weight: 700;
    font-size: $font-size-xl;
    line-height: 22px;
    color: $text-black;
    margin: 0;
  }

  .popupImage {
    border-radius: $border-radius-md;
    width: 300px;
    height: 160px;
    margin: 0;
  }

  .reward {
    font-size: $font-size-xxxl;
    font-weight: 700;
    line-height: 29px;
    color: $text-black;
    text-align: center;
    margin: 0;
  }

  .text {
    font-size: $font-size-md;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    color: $textGrey;
    margin: 0;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    min-width: 300px;
  }

  @include tabletAndAbove() {
    min-width: 340px;
  }

  .successBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    height: 60px;
    color: $text-white;
    background-color: $colorPrimary;
    border: 1px solid $colorPrimary;
    border-radius: 16px;
    font-size: $font-size-lg;

    &:hover,
    &:focus {
      background-color: darken($color: $colorPrimary, $amount: 10);
      border: 1px solid darken($color: $colorPrimary, $amount: 10);
    }

    @include tabletAndAbove() {
      padding: 1.25rem pxToRem(45);
    }

    &.btnOutlineDark {
      border-radius: $border-radius-xl;
      border: 1px solid darken($color: $colorBGGrey, $amount: 4);
      background-color: transparent;
      color: $text-black;
      box-shadow: none;
      font-weight: 400;
      font-size: $font-size-lg;

      &:hover,
      &:focus {
        background-color: darken($color: $colorBGGrey, $amount: 5);
        color: $colorPrimary;
      }
    }
  }
}
