@use '~include-media/dist/include-media' as *;

$phone: 480px;
$tablet: 768px;
$desktop: 1024px;
$large-desktop: 1200px;

$breakpoints: (
  phone: $phone,
  tablet: $tablet,
  desktop: $desktop,
  large-desktop: $large-desktop,
);

/* Inclusive and exclusive operators for a finer control over the intervals */
@mixin mobile {
  @include media('<tablet') {
    @content;
  }
}

@mixin tabletAndAbove {
  @include media('>=tablet') {
    @content;
  }
}

@mixin desktopAndAbove {
  @include media('>=desktop') {
    @content;
  }
}

@mixin smallMobile {
  @include media('<=phone') {
    @content;
  }
}

@mixin smallHeightMobile {
  @media (max-height: 600px) {
    @content;
  }
}

@mixin largeDesktop {
  @include media('>=large-desktop') {
    @content;
  }
}

@mixin betweenTabletAndDesktop {
  @include media('>=tablet', '<1024px') {
    @content;
  }
}

@mixin smallHeightDesktop {
  @media (max-height: 768px) {
    @content;
  }
}

@mixin mobileAndLandscape {
  @media (max-height: 600px) and (orientation: landscape) {
    @content;
  }
}
