@use 'theme' as *;
@use 'breakpoints' as *;

@mixin anchor-style {
  color: $textPrimary;

  &:hover {
    color: $colorPrimary;
  }
}

@mixin smooth-font {
  $smoothTextShadowColor: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: $smoothTextShadowColor;
}

@mixin font-size-reset {
  font-size: 15px;

  @include tabletAndAbove {
    font-size: 16px;
  }
}

@mixin button-reset() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  border-radius: initial;
  transform: inherit;
  cursor: pointer;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE, Edge and Firefox
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@function stripUnit($value) {
  @return calc($value / calc($value * 0 + 1));
}

@function pxToRem($pxValue) {
  @return #{calc(stripUnit($pxValue) / stripUnit(16))}rem;
}
