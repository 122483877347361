// TODO: use right colors
$loyalty-aqua: #169a89;
$loyalty-aqua-faded: #c9e7e1;
$loyalty-red: '#ea6464';
$loyalty-grey: #919191;
$loyalty-grey-faded: #dfdfdf;
$border-grey: #d8d8d8;
$popup-grey: #f3f3f3;

$bg-white: #fafafa;
$bg-grey: #f8f9f8;

$text-black: #333;
$text-white: #e7e7e7;

$danger-button: #e65359;
$focus-outline: #1f8fe1;

$colorPrimary: $loyalty-aqua;
$colorAlternate: $loyalty-aqua-faded;
$colorGrey: $loyalty-grey-faded;
$colorError: $loyalty-red;
$colorBG: $bg-white;
$colorBGGrey: $bg-grey;
$textPrimary: $text-black;
$textAlternate: $text-white;
$textGrey: $loyalty-grey;
$borderPrimary: $loyalty-aqua-faded;
$borderAlternate: $border-grey;
$borderError: $loyalty-red;
$popupGreyBG: $popup-grey;

// variables
$headerHeight: 0rem;
$footerHeight: 0rem;

$border-radius: 2px;
$border-radius-xs: $border-radius * 2;
$border-radius-sm: $border-radius * 3;
$border-radius-md: $border-radius * 4;
$border-radius-lg: $border-radius * 6;
$border-radius-xl: $border-radius * 8;

$vh100: calc(var(--webrms-vh, 1vh) * 100);

$font-size-xs: 0.625; // (10)
$font-size-sm: 0.75rem; // (12)
$font-size-md: 0.875rem; // (14)
$font-size-lg: 1rem; // (16)
$font-size-xl: 1.125rem; // (18)
$font-size-xxl: 1.25rem; // (20)
$font-size-xxxl: 1.5rem; // (24)
