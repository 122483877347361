@use 'src/styles/breakpoints' as *;
@use 'src/styles/theme' as *;
@use 'src/styles/mixins' as *;

:root {
  --payment-warning-height: 40px;
  --blocked-access-z-index: 10001;
}

.alertsContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  font-size: $font-size-lg;
  width: 100%;
  height: auto; // must be auto so that we don't have to manage the height on different screen sizes
  background: $danger-button;
  text-align: left;
  font-weight: 400;
  color: $text-white;
  z-index: 6;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.referral {
    background: $text-white;
  }

  strong {
    color: $text-white;
  }

  .alert {
    position: relative;
    padding: 10px;

    &.referralBanner {
      background: $bg-white;
    }

    &:not(:last-child) {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }
  }

  .alertMessages {
    list-style: none;
    padding: 0;
    margin: 0;

    .message {
      padding: 0;
      margin: 0;
    }

    :only-child {
      list-style: none;
      margin-left: 0;
    }

    .referralMessage {
      color: $text-black;
    }

    .firstMessage {
      display: list-item;
      margin-right: 25px;
      @include mobile {
        margin-right: 15px;
      }
      @include desktopAndAbove {
        margin-right: 125px; // 25px for close + 100px for paynow button
      }
    }
  }

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    @include mobile {
      top: 10px;
    }
  }

  .actionButtonContainer {
    position: relative;
    top: 5px;
    display: flex;
    gap: 0.5rem;

    @include desktopAndAbove {
      position: absolute;
      top: 10px;
      right: 35px;
    }

    &.referralButtonContainer {
      @include desktopAndAbove {
        right: 10px;
      }
    }
  }

  .actionButtonNoThanks {
    @include button-reset;
    background-color: transparent;
    font-size: $font-size-md;
    color: $text-black;
    height: 25px;
    border-radius: $border-radius-md;
    padding: 0 0.5rem;

    &:hover {
      background-color: darken($color: $text-white, $amount: 5);
    }
  }

  .actionButton {
    @include button-reset;
    background-color: $text-white;
    color: $text-black;
    border-radius: $border-radius-md;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    font-size: $font-size-md;
    min-width: 100px;
    padding: 0 pxToRem(8);
    height: 25px;
    text-align: center;

    &.referralBtn {
      background-color: $colorPrimary;
      color: $text-white;

      &:hover {
        background-color: darken($color: $colorPrimary, $amount: 10);
      }
    }

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $text-white, $amount: 10);
    }

    &:disabled {
      background-color: $colorGrey;
      cursor: default;
      outline: none;
    }
  }
}
