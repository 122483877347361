@use 'src/styles/breakpoints' as *;

.description {
  ul {
    list-style: disc;
    @include tabletAndAbove {
      padding-left: 15px;
    }
  }
  li {
    display: list-item;
  }
}
