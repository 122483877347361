@use 'src/styles/breakpoints' as *;
@use 'src/styles/theme' as *;
@use 'src/styles/mixins' as *;

:root {
  --payment-warning-height: 40px;
  --blocked-access-z-index: 10001;
}

.paymentWarning {
  position: fixed;
  top: 0;
  font-size: $font-size-lg;
  padding: 10px;
  width: 100%;
  left: 0;
  height: auto; // must be auto so that we don't have to manage the height on different screen sizes
  background: $danger-button;
  text-align: left;
  font-weight: 400;
  color: var(--text-white);
  z-index: 6;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  strong {
    color: var(--text-white);
  }

  p {
    margin: 0;
    padding: 0;
  }

  .warningMessages {
    list-style: none;
    padding: 0;
    margin: 0;

    .message {
      padding: 0;
      margin: 0;
    }

    :only-child {
      list-style: none;
      margin-left: 0;
    }

    .firstMessage {
      display: list-item;
      margin-right: 25px;
      @include mobile {
        margin-right: 15px;
      }
      @include desktopAndAbove {
        margin-right: 125px; // 25px for close + 100px for paynow button
      }
    }
  }

  .closeIcon {
    position: fixed;
    right: 10px;
    top: 15px;
    cursor: pointer;
    @include mobile {
      top: 10px;
    }
  }

  .payNow {
    @include button-reset;
    cursor: pointer;
    position: relative;
    top: 5px;
    background-color: $colorBG;
    color: var(--text-primary);
    border-radius: $border-radius-md;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    font-size: $font-size-md;
    width: 100px;
    height: 25px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include desktopAndAbove {
      position: absolute;
      top: 10px;
      right: 35px;
    }

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $colorBG, $amount: 10);
    }

    &:disabled {
      background-color: var(--color-grey);
      cursor: default;
      outline: none;
    }
  }
}

.blockedAccessModal {
  > div {
    cursor: not-allowed;
  }
}

$blocked-section-width: 650px;

.blockedReactModal__Content {
  position: absolute;
  background-color: $colorBG;
  padding: 0;
  margin: 0;
  box-shadow: 0px 2px 4px 2px #0000002f;
  max-height: 95vh;
  border-radius: $border-radius-xl;
  outline: none;
  inset: 50% auto auto 50%;
  // subtract with width of modal from 100% of screen and divide into two part to get the center of screen
  left: calc(calc(100% - #{$blocked-section-width}) / 2);
  top: 10%;
  right: auto;
  bottom: auto;

  @include smallHeightMobile {
    top: 5%;
  }
  @include mobile {
    left: 2%;
    width: 95%;
  }
}

.blockedTopRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  padding: 20px;

  .logoutWrapper {
    display: flex;
    a {
      border-bottom: 1.25px solid currentColor;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.blockedBottomRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 20px 20px;
}

.blockedBackButton {
  position: absolute;
  top: 20px;
  padding-left: 20px;

  @include mobile() {
    position: relative;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $colorPrimary;
  font-size: 20px;
  cursor: pointer;

  svg {
    margin-bottom: 0;
    margin-right: 0.5rem;

    path {
      fill: $colorPrimary;
    }
  }
}

.blockedAccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 32px;
  width: $blocked-section-width;
  @include mobile() {
    width: 100%;
  }

  figure {
    margin: 0;
    svg {
      height: 65px;
      width: 234px;
      @include tabletAndAbove() {
        height: 85px;
      }
      path,
      rect,
      polygon {
        fill: $colorPrimary;
      }
    }
  }

  .title {
    color: $danger-button;
    font-size: $font-size-xl;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
  }

  .description {
    color: var(--text-primary);
    font-size: $font-size-lg;
    line-height: 19px;
    padding: 0 32px;
    @include mobile {
      padding: 0 16px;
    }
    max-height: 250px;
    overflow: auto;
  }

  .goToBillingButton {
    @include button-reset;
    cursor: pointer;
    background-color: $colorPrimary;
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: 19px;
    text-align: center;
    color: var(--text-white);
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius-xl;
    width: 340px;
    height: 60px;
    @include mobile {
      width: 100%;
    }

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $colorPrimary, $amount: 5);
    }

    &:disabled {
      background-color: var(--color-grey);
      cursor: default;
      outline: none;
    }
  }
}
