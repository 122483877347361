@use 'src/styles/breakpoints' as *;
@use 'src/styles/theme' as *;

// not using css module because we want these exact class names 'coz that how React Modal lib works
.ReactModal {
  &__customContent {
    position: absolute;
    top: 3rem;
    left: 3rem;
    right: 3rem;
    bottom: 3rem;
    background: $colorBG;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 2rem;

    @media (max-width: 991px) {
      -webkit-overflow-scrolling: touch;
    }

    // overflow: hidden;
    margin: 0 auto;

    transition: transform 200ms ease-in-out;
    transform: translateX(100vw);

    @media (min-width: calc(992px + (992px / 10))) {
      max-width: calc(992px * 90 / 100);
    }

    @include mobile {
      left: 10px;
      right: 10px;
    }

    &.ScrollableBody {
      overflow: hidden;

      &.dimmed:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__customContentSmall {
    position: absolute;
    top: 30%;
    left: calc(50% - calc(40rem / 2));
    width: 40rem;
    background: $colorBG;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 2rem;

    overflow: hidden;
    margin: 0 auto;

    transition: transform 200ms ease-in-out;
    transform: translateX(100vw);

    @include betweenTabletAndDesktop {
      left: calc(50% - calc(30rem / 2));
      width: 30rem;
    }

    @include mobile {
      left: 10px;
      right: 10px;
      width: 100%;
    }

    &.ScrollableBody {
      overflow: hidden;
    }
  }

  &__Content--after-open {
    transform: translateX(0);
  }

  &__Content--before-close {
    transform: translateX(-100vw);
  }

  &__customOverlay {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__closeButton {
    position: absolute;
    margin: 0;
    padding: 0.75rem;
    right: 0rem;
    top: 0rem;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 3;

    &:after {
      content: '×';
      font-size: 2rem;
      line-height: 1;
      z-index: 2;
      padding: 0 0.5rem 0 1rem;
    }

    .ReactModal__customContent.ScrollableBody &,
    .ReactModal__customContentSmall.ScrollableBody & {
      z-index: 0;

      &:after {
        background: $colorBG;
      }
    }
  }

  &__customBody {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
}
