@use 'theme' as *;
@use 'mixins' as *;

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  @include smooth-font;
  @include font-size-reset;
  color: $textPrimary;
  background-color: $colorBG;

  @media (max-width: 991px) {
    -webkit-overflow-scrolling: touch;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  @include smooth-font;
  @include font-size-reset;
  color: $textPrimary;
  line-height: 1.6;
  background-color: $colorBG;
  position: fixed;

  @media (max-width: 991px) {
    -webkit-overflow-scrolling: touch;
  }
}

html,
body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  touch-action: manipulation;
}

#root,
.loyalty {
  height: 100%;
  overflow-y: auto;

  @media (max-width: 991px) {
    -webkit-overflow-scrolling: touch;
  }

  > div {
    height: 100%;
  }
  .Toastify {
    height: auto;
  }
}

a {
  color: $textPrimary;
  text-decoration: none;
}
